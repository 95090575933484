









import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    label: {
      type: String,
    },
    value: {
      type: Boolean,
    },
  },
});
