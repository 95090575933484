import { ValidationRuleWithParams } from "@vuelidate/core";
import {
  helpers,
  required as vuelidateRequired,
  requiredIf as vuelidateRequiredIf,
  maxLength as vuelidateMaxLength,
  email as vuelidateEmail,
  sameAs as vuelidateSameAs,
} from "@vuelidate/validators";

export const required = (label?: string | null): ValidationRuleWithParams =>
  helpers.withMessage(({ $property }) => {
    return `${label ? label : $property}は必須項目です。`;
  }, vuelidateRequired);

export const requiredIf = (
  prop: boolean | string | (() => boolean | Promise<boolean>),
  label?: string | null
): ValidationRuleWithParams =>
  helpers.withMessage(({ $property }) => {
    return `${label ? label : $property}は必須項目です。`;
  }, vuelidateRequiredIf(prop));

export const maxLength = (length?: number | null): ValidationRuleWithParams => {
  length = length ?? Infinity;
  return helpers.withMessage(({ $model }) => {
    let prefix = "";
    switch (typeof $model) {
      case "string":
        prefix = "文字";
        break;
      case "number":
        prefix = "桁";
        break;
      default:
        break;
    }
    return `${length}${prefix}以内で入力してください。`;
  }, vuelidateMaxLength(length));
};

export const email = (label?: string | null): ValidationRuleWithParams =>
  helpers.withMessage(({ $property }) => {
    return `${label ? label : $property}が正しい形式ではありません。`;
  }, vuelidateEmail);

export const tel = (label?: string | null): ValidationRuleWithParams =>
  helpers.withParams(
    { type: "tel" },
    helpers.withMessage(
      ({ $property }) => {
        return `${label ? label : $property}が正しい形式ではありません。`;
      },
      (val: string | null) => {
        if (!val) {
          return true;
        }
        if (val.match(/[^0-9]/) || val.length !== 11) {
          return false;
        }

        return true;
      }
    )
  );

export const sameAs = vuelidateSameAs;
